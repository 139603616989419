var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { router } from "@inertiajs/react";
import { Loader } from "@googlemaps/js-api-loader";
/**
 * investment's units available - (investment issuer's required_ownership_percentage x investment's units available) - sum of all other investment listing units for the investment.
 *
 * @param error
 * @param unitsAvailable
 * @param issuerRequiredPercentage
 * @param sumOfInvestmentListingUnits
 */
export const calcMaxUnits = (error, unitsAvailable, issuerRequiredPercentage, sumOfInvestmentListingUnits) => {
    const issuerConvertedPercentage = issuerRequiredPercentage / 100;
    const maxUnits = Math.round(unitsAvailable -
        issuerConvertedPercentage * unitsAvailable -
        sumOfInvestmentListingUnits);
    return maxUnits;
};
/**
 * Effective Investor Rate = investment's interest rate - investment's servicing rate + the Return Rate Modifier
 *
 * @param error
 * @param investmentInterestRate
 * @param investmentServicingRate
 * @param returnRateModifier
 */
export const calcEffectiveInvestorRate = (error, investmentInterestRate, investmentServicingRate, returnRateModifier) => {
    const interestMinusServicing = investmentInterestRate - investmentServicingRate;
    const finalInterest = interestMinusServicing + returnRateModifier;
    return parseFloat(finalInterest).toFixed(2);
};
/**
 * Calculate the investor rate
 *
 * @param interestRate
 * @param servicingRate
 */
export const calcInvestorRate = (interestRate, servicingRate) => {
    return (interestRate - servicingRate).toFixed(2);
};
/**
 * Update the Pagination
 *
 * @param page_value
 * @param page
 * @param url
 */
export const updatePagination = (page_value, page, url) => {
    if (page_value) {
        if (url.includes("page=")) {
            const pattern = /page=([0-9]+)/gm;
            const currentUrl = url;
            const newUrl = currentUrl.replace(pattern, "page=" + page_value);
            router.get(newUrl, {}, { preserveScroll: true });
        }
        else {
            if (page_value == page) {
                return;
            }
            const pagination = (url.includes("?") ? "&" : "?") + "page=" + page_value;
            router.get(url + pagination, {}, { preserveScroll: true });
        }
    }
};
/**
 * Submit Search Function
 *
 * @param locationReact
 */
export const submitSearch = (locationReact) => {
    let search = locationReact.search;
    if (search.includes("page=")) {
        const pattern = /page=([0-9]+)/gm;
        search = search.replace(pattern, "page=1");
    }
    router.get(locationReact.pathname + search, {}, { preserveScroll: true });
};
/**
 * Get param urls from string
 *
 * @param urlString
 * @param param
 */
export const getParamFromUrlString = (urlString, param) => {
    const searchParam = new URLSearchParams(urlString.substring(urlString.indexOf("?")));
    return searchParam.get(param);
};
/**
 * Calculate the units based off of the units multiplier
 *
 * eg 100 units should be stored as 10000
 *
 * @param units
 * @param units_multiplier
 */
export const unitsMultipliedByMultiplier = (units, units_multiplier) => {
    if (units === 0) {
        return 0;
    }
    else {
        return Number(units * units_multiplier);
    }
};
/**
 * Calculate the units divided by the units multiplier
 *
 * eg 10000 should be 100 on the views
 *
 * @param units
 * @param units_multiplier
 */
export const unitsDividedByMultiplier = (units, units_multiplier) => {
    if (units === 0) {
        return 0;
    }
    else {
        return Number(units / units_multiplier);
    }
};
/**
 * Format the number to 999,999,999,999.99 format.
 *
 * @param number
 * @param decimalDigits
 */
export const formatNumberToMoneyFormat = (number, decimalDigits = 2) => {
    if (isNaN(number) === true || isNaN(decimalDigits) === true) {
        return "0";
    }
    if (!number) {
        return 2 === decimalDigits ? "0.00" : "0";
    }
    return Number(number)
        .toFixed(decimalDigits)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
export const formatNumber = (units, units_multiplier = 100) => {
    units = isNaN(units) === false ? units : 0;
    if (Number.isInteger(units) && 100 !== units_multiplier) {
        return units.toLocaleString(undefined, { useGrouping: true });
    }
    return formatNumberToMoneyFormat(units);
};
export const unitMaskingBlock = (units_multiplier) => {
    return {
        num: {
            mask: Number,
            radix: ".",
            scale: units_multiplier == 1 ? 0 : 2,
            signed: true,
            thousandsSeparator: ",",
            padFractionalZeros: units_multiplier == 1 ? 0 : 2,
            normalizeZeros: false,
            unmask: true, // true|false|'type
        },
    };
};
export const holdBackDaysMaskingBlock = () => {
    return {
        num: {
            mask: Number,
            lazy: false,
            unmask: true,
        },
    };
};
export const morcadoIdMaskingBlock = () => {
    return {
        num: {
            mask: Number,
            lazy: false,
            unmask: true,
        },
    };
};
export const ltvMaskingBlock = () => {
    return {
        num: {
            mask: Number,
            radix: ".",
            scale: 2,
            signed: true,
            thousandsSeparator: ",",
            padFractionalZeros: 2,
            normalizeZeros: false,
            unmask: true, // true|false|'type
        },
    };
};
/**
 * Reverse Geocode the lat lng based on the address
 *
 * @param address
 * @param api_key
 * @returns Promise<LatLng> A Promise that resolves to the LatLng object
 */
export const reverseGeoCode = (address, api_key) => __awaiter(void 0, void 0, void 0, function* () {
    const loader = new Loader({
        apiKey: api_key,
        version: "weekly",
    });
    return new Promise((resolve, reject) => {
        loader.load().then(() => __awaiter(void 0, void 0, void 0, function* () {
            /* eslint-disable */
            const { Geocoder } = (yield google.maps.importLibrary("geocoding"));
            /* eslint-enable */
            const geocoder = new google.maps.Geocoder();
            geocoder
                .geocode({ address: address })
                .then((response) => {
                if (response.results[0]) {
                    const results = {
                        lat: response.results[0].geometry.location.lat().toString(),
                        lng: response.results[0].geometry.location.lng().toString(),
                        error: "",
                    };
                    resolve(results);
                }
                else {
                    reject(new Error("Geocoding response has no results."));
                }
            })
                .catch((error) => {
                if (error.code == "ZERO_RESULTS") {
                    const results = {
                        lat: "",
                        lng: "",
                        error: "Geocoding response has no results",
                    };
                    resolve(results);
                }
                reject(error);
            });
        }));
    });
});
/**
 * Scrambles a number to hide certain values
 *
 * @param number
 */
export const scrambleNumber = (number) => {
    if (number) {
        const returnNumber = [...number];
        const startToShowNumbers = number.length - 4;
        for (let i = 0; i < number.length; i++) {
            if (i < startToShowNumbers) {
                returnNumber[i] = "*";
            }
        }
        return returnNumber.toString().replace(/,/g, "");
    }
    return null;
};
/**
 * Search an object for a key
 *
 * @param obj
 * @param valueToFind
 */
export const findKeyByValue = (obj, valueToFind) => {
    if (valueToFind !== null &&
        valueToFind !== undefined &&
        valueToFind.length > 1) {
        for (const key in obj) {
            if (key == valueToFind.toString()) {
                return obj[key];
            }
        }
    }
    return null;
};
export const getPercent = (value, total, round = true) => {
    if (isNaN(value) === true || isNaN(value) === true) {
        return 0;
    }
    if (value <= 0) {
        return 0;
    }
    if (total <= 0) {
        return 0;
    }
    let percent;
    if (round === false) {
        percent = (value / total) * 100;
    }
    else {
        percent = Math.round((value / total) * 100);
    }
    return percent > 100 ? 100.0 : percent.toFixed(2);
};
export const getLastWordOfString = (string) => {
    const strArr = string.split(" ");
    return strArr[strArr.length - 1];
};
export const returnEffectiveInvestorRateColumn = (investment_interest_rate, investment_servicing_rate, investment_listing_return_rate_modifier_bps) => {
    const investor_rate = calcInvestorRate(investment_interest_rate, investment_servicing_rate);
    const effective_investor_rate = calcEffectiveInvestorRate("", investment_interest_rate, investment_servicing_rate, Number(investment_listing_return_rate_modifier_bps));
    let style = "";
    if (Number(effective_investor_rate) > Number(investor_rate)) {
        style = "red-important";
    }
    if (Number(effective_investor_rate) < Number(investor_rate)) {
        style = "green-important";
    }
    return (_jsxs(_Fragment, { children: [_jsxs("span", Object.assign({ className: style }, { children: [effective_investor_rate, "%"] })), investment_listing_return_rate_modifier_bps !== "0.00" && (_jsxs("span", { children: [investor_rate, investment_listing_return_rate_modifier_bps > 0 ? "+" : "", investment_listing_return_rate_modifier_bps, "%"] }))] }));
};
export const returnInvestorEffectiveRateAndRateModifiers = (investment, investment_listing) => {
    return returnEffectiveInvestorRateColumn(investment === null || investment === void 0 ? void 0 : investment.interest_rate, investment === null || investment === void 0 ? void 0 : investment.servicing_rate, investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.return_rate_modifier_bps);
};
export const convertFromUTCtoLocalTime = (datetime) => {
    let utc_date = new Date();
    if (datetime.length === 10) {
        utc_date = new Date(datetime + "T00:00:00Z");
    }
    else if (datetime.length === 19) {
        utc_date = new Date(datetime.replace(" ", "T") + "Z");
    }
    else {
        throw new Error("Invalid date or datetime");
    }
    const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const local_datetime = utc_date.toLocaleString("en-US", {
        timeZone: user_timezone,
    });
    return local_datetime;
};
export const getFormattedDateTime = (datetime = null) => {
    let today = new Date();
    if (datetime !== null) {
        today = new Date(datetime);
    }
    // Get month abbreviation
    const monthAbbreviation = today.toLocaleString("default", {
        month: "short",
    });
    // Get day and year
    const day = today.getUTCDate();
    const year = today.getUTCFullYear();
    // Get time
    const time = today.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    // Construct formatted date string
    const formatted_date = `${monthAbbreviation}. ${day}, ${year} ${time}`;
    return formatted_date;
};
export const convertValueToLabel = (value) => {
    // Replace underscores with spaces
    let label = value.replace(/_/g, " ");
    // Capitalize the first letter of each word
    label = label.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
    return label;
};
