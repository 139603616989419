import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { router, usePage } from "@inertiajs/react";
import { Container, Column, Form, BasicRow, TextCell, Table, CellHeader, RawCell, store, pushNotification, } from "@truenorthmortgage/olympus";
import "./BankAccountForm.scss";
import { findKeyByValue, scrambleNumber } from "../../../utils/utils";
import { bank_institution_numbers } from "../../../utils/Bankdata";
const EditBankAccountForm = ({ bank_accounts = [], investor_uuid = null, callback = null, }) => {
    const { flash } = usePage().props;
    const closeModel = () => {
        callback();
    };
    useEffect(() => {
        if ((flash === null || flash === void 0 ? void 0 : flash.success) !== undefined &&
            (flash === null || flash === void 0 ? void 0 : flash.success) !== "" &&
            (flash === null || flash === void 0 ? void 0 : flash.success) !== null) {
            closeModel();
        }
    }, [flash]);
    const [bank_accounts_to_delete, setBankAccountsToDelete] = useState([]);
    const [disable_last_bank_account, setDisableLastBankAccount] = useState(false);
    // WHen we hit the limit of how many bank accounts we can delete disable the last checkbox
    useEffect(() => {
        if (Array.isArray(bank_accounts_to_delete) &&
            Array.isArray(bank_accounts) &&
            bank_accounts_to_delete.length === bank_accounts.length - 1) {
            // Find the unchecked account
            const unchecked = bank_accounts.find((account) => !bank_accounts_to_delete.includes(account.uuid));
            setDisableLastBankAccount(unchecked ? unchecked.uuid : null);
        }
        else {
            setDisableLastBankAccount(false);
        }
    }, [bank_accounts_to_delete, bank_accounts]);
    // Add bank accounts to remove throw a error if they try to remove all of them !
    const addBankAccountToDelete = (e) => {
        const checked = e.target.checked;
        const bank_account_id = e.target.value;
        if (checked === true) {
            // If we dont have all accounts selected
            if (bank_accounts_to_delete.length === bank_accounts.length - 1) {
                // Show a error message or disable the last unchecked row?
                store.dispatch(pushNotification({
                    message: "At least one Bank Account is required.",
                    class: "error",
                }));
            }
            else {
                setBankAccountsToDelete((prevIds) => [...prevIds, bank_account_id]);
            }
        }
        else {
            // Remove the ID !
            setBankAccountsToDelete([
                ...bank_accounts_to_delete.filter((prevIds) => prevIds !== bank_account_id),
            ]);
        }
    };
    const showBankNameAndAccount = (bank_account) => {
        const bank_name = findKeyByValue(bank_institution_numbers, bank_account === null || bank_account === void 0 ? void 0 : bank_account.bank_number);
        return ((bank_name !== null && bank_name !== void 0 ? bank_name : bank_account === null || bank_account === void 0 ? void 0 : bank_account.bank_number) +
            " " +
            scrambleNumber(bank_account === null || bank_account === void 0 ? void 0 : bank_account.account_number));
    };
    const [loading, setLoading] = useState(false);
    // Submit The Form !
    const handleSubmit = (e) => {
        e.preventDefault();
        const query_string = encodeURIComponent(JSON.stringify(bank_accounts_to_delete));
        if (bank_accounts_to_delete.length === 0) {
            store.dispatch(pushNotification({
                message: "At least one bank account must be selected.",
                class: "error",
            }));
            return false;
        }
        if (window.confirm("Do you really want to delete the following bank accounts?")) {
            router.delete("/bank-account/delete-accounts?investor_uuid=" +
                investor_uuid +
                "&ids=" +
                query_string, {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
            });
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ className: "create-bank-account-form-container" }, { children: _jsxs(Column, Object.assign({ columnStyle: "full" }, { children: [_jsx(Column, Object.assign({ columnStyle: "full create-bank-account-form" }, { children: _jsx("div", Object.assign({ className: "center flex-column" }, { children: _jsx("h2", { children: "Delete Bank Accounts" }) })) })), _jsxs(Column, Object.assign({ columnStyle: "full delete-bank-account-table full-width-table" }, { children: [_jsx("p", { children: "At least one bank account must be linked." }), _jsx(Form, Object.assign({ className: "delete-bank-account-form full" }, { children: _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Empty", isEmpty: (bank_accounts === null || bank_accounts === void 0 ? void 0 : bank_accounts.length) < 1, className: "full", style: { width: "100%" } }, { children: [_jsxs(CellHeader, Object.assign({ className: "bank-accounts-header section-header" }, { children: [_jsx(RawCell, {}), _jsx(RawCell, { children: "FRIENDLY ID" }), _jsx(RawCell, { children: "BANK AND ACCOUNT #" }), _jsx(RawCell, { children: "Account Holder Name" })] })), bank_accounts === null || bank_accounts === void 0 ? void 0 : bank_accounts.map((bank_account) => (_jsxs(BasicRow, Object.assign({ rowClasses: [
                                                "faux-row",
                                                disable_last_bank_account ? "disabled-row" : "",
                                            ] }, { children: [_jsx(RawCell, { children: _jsxs("label", Object.assign({ className: "checkbox" }, { children: [_jsx("input", { name: "delete_bank_account", type: "checkbox", value: bank_account === null || bank_account === void 0 ? void 0 : bank_account.uuid, id: bank_account === null || bank_account === void 0 ? void 0 : bank_account.uuid, onChange: addBankAccountToDelete, disabled: disable_last_bank_account === bank_account.uuid }), _jsx("span", { className: "checkmark" })] })) }), _jsx(TextCell, { children: bank_account === null || bank_account === void 0 ? void 0 : bank_account.friendly_id }, bank_account === null || bank_account === void 0 ? void 0 : bank_account.bank_number), _jsx(TextCell, { children: showBankNameAndAccount(bank_account) }, bank_account === null || bank_account === void 0 ? void 0 : bank_account.account_number), _jsx(TextCell, { children: (() => {
                                                        if (bank_account === null || bank_account === void 0 ? void 0 : bank_account.account_holder_name) {
                                                            return bank_account === null || bank_account === void 0 ? void 0 : bank_account.account_holder_name;
                                                        }
                                                        else {
                                                            return " ";
                                                        }
                                                    })() })] }), (bank_account === null || bank_account === void 0 ? void 0 : bank_account.bank_number) + Math.random())))] })) }))] })), _jsxs(Column, Object.assign({ columnStyle: "full create-bank-account-form" }, { children: [_jsx("button", Object.assign({ type: "button", disabled: loading === true, className: "button delete-button", onClick: (e) => handleSubmit(e), style: { marginLeft: "auto" } }, { children: "Delete Accounts" })), _jsx("button", Object.assign({ type: "button", className: "button submit-button", style: { marginRight: "0", marginLeft: "10px", marginTop: "0" }, onClick: closeModel }, { children: "Close" }), "add_bank_account_modal_create_button")] }))] }), "edit-investment-column") })) }));
};
export default EditBankAccountForm;
