import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { router, usePage } from "@inertiajs/react";
import { Container, Column, Form, Table, CellHeader, RawCell, BasicRow, TextCell, } from "@truenorthmortgage/olympus";
import { formatNumber, unitsDividedByMultiplier, formatNumberToMoneyFormat, } from "../../../utils/utils";
import Loading from "../Loading";
const InterestIncomeTransactionDetail = ({ transaction = null, callback = null }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54;
    const url = usePage().url;
    const { CONSTANTS, flash, current_date } = usePage().props;
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getTransactionData("detailed");
    }, [transaction]);
    useEffect(() => {
        if ((flash === null || flash === void 0 ? void 0 : flash.error) === "Interest Income Details not found.") {
            if (callback) {
                callback();
            }
        }
    }, [flash]);
    const getTransactionData = (type) => {
        var _a, _b, _c;
        if (transaction !== null &&
            (transaction === null || transaction === void 0 ? void 0 : transaction.uuid) !==
                ((_c = (_b = (_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.transaction_detail) === null || _b === void 0 ? void 0 : _b.journal_entry_item) === null || _c === void 0 ? void 0 : _c.uuid)) {
            router.visit("/investors/investment-account/transaction-detail", {
                data: { uuid: transaction === null || transaction === void 0 ? void 0 : transaction.uuid, url: url, type: type },
                only: ["flash"],
                onBefore: () => setLoading(true),
                onFinish: () => {
                    setLoading(false);
                },
                preserveScroll: () => true,
                preserveState: () => true,
            });
        }
        else {
            setLoading(false);
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "generic-modal-container" }, { children: [_jsx(Column, Object.assign({ columnStyle: "full" }, { children: _jsx("div", Object.assign({ className: "center" }, { children: _jsx("h2", { children: "Interest Income Details" }) })) })), _jsxs(Column, { children: [loading === false && (_jsxs(_Fragment, { children: [_jsxs(Form, Object.assign({ className: "modal-form-purchase-investment" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Effective Date" }), _jsxs("div", { children: [((_c = (_b = (_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.transaction_detail) === null || _b === void 0 ? void 0 : _b.journal_entry_item) === null || _c === void 0 ? void 0 : _c.effective_date) !==
                                                            ((_f = (_e = (_d = flash === null || flash === void 0 ? void 0 : flash.form) === null || _d === void 0 ? void 0 : _d.transaction_detail) === null || _e === void 0 ? void 0 : _e.journal_entry_item) === null || _f === void 0 ? void 0 : _f.posted_date) ? (((_j = (_h = (_g = flash === null || flash === void 0 ? void 0 : flash.form) === null || _g === void 0 ? void 0 : _g.transaction_detail) === null || _h === void 0 ? void 0 : _h.journal_entry_item) === null || _j === void 0 ? void 0 : _j.status) !==
                                                            CONSTANTS.JOURNAL_ENTRY_ITEM.STATUS.PENDING.value &&
                                                            ((_m = (_l = (_k = flash === null || flash === void 0 ? void 0 : flash.form) === null || _k === void 0 ? void 0 : _k.transaction_detail) === null || _l === void 0 ? void 0 : _l.journal_entry_item) === null || _m === void 0 ? void 0 : _m.effective_date) > current_date ? (_jsxs("div", Object.assign({ style: {
                                                                display: "flex",
                                                                width: "100%",
                                                                flexDirection: "column",
                                                            } }, { children: [_jsx("span", { children: (_q = (_p = (_o = flash === null || flash === void 0 ? void 0 : flash.form) === null || _o === void 0 ? void 0 : _o.transaction_detail) === null || _p === void 0 ? void 0 : _p.journal_entry_item) === null || _q === void 0 ? void 0 : _q.effective_date }), _jsx("span", { children: "(pending)" })] }))) : (_jsx("div", { children: _jsx("span", { children: (_t = (_s = (_r = flash === null || flash === void 0 ? void 0 : flash.form) === null || _r === void 0 ? void 0 : _r.transaction_detail) === null || _s === void 0 ? void 0 : _s.journal_entry_item) === null || _t === void 0 ? void 0 : _t.effective_date }) }))) : (_jsx("div", { children: (_w = (_v = (_u = flash === null || flash === void 0 ? void 0 : flash.form) === null || _u === void 0 ? void 0 : _u.transaction_detail) === null || _v === void 0 ? void 0 : _v.journal_entry_item) === null || _w === void 0 ? void 0 : _w.effective_date })), _jsxs("div", Object.assign({ style: { marginTop: "10px" } }, { children: [_jsx("label", Object.assign({ style: { marginTop: "6px" } }, { children: "Posted Date" })), _jsx("div", Object.assign({ style: {
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        flexDirection: "column",
                                                                    } }, { children: _jsxs("span", Object.assign({ className: "italic" }, { children: [" ", (_z = (_y = (_x = flash === null || flash === void 0 ? void 0 : flash.form) === null || _x === void 0 ? void 0 : _x.transaction_detail) === null || _y === void 0 ? void 0 : _y.journal_entry_item) === null || _z === void 0 ? void 0 : _z.posted_date] })) }))] })), ((_2 = (_1 = (_0 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _0 === void 0 ? void 0 : _0.transaction_detail) === null || _1 === void 0 ? void 0 : _1.journal_entry_item) === null || _2 === void 0 ? void 0 : _2.status) !==
                                                            CONSTANTS.JOURNAL_ENTRY_ITEM.STATUS.ACTIVE.value && (_jsxs("span", { children: ["(", (_5 = (_4 = (_3 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _3 === void 0 ? void 0 : _3.transaction_detail) === null || _4 === void 0 ? void 0 : _4.journal_entry_item) === null || _5 === void 0 ? void 0 : _5.status_label, ")"] }))] })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Income from Investment" }), _jsxs("div", { children: [(_8 = (_7 = (_6 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _6 === void 0 ? void 0 : _6.transaction_detail) === null || _7 === void 0 ? void 0 : _7.investment) === null || _8 === void 0 ? void 0 : _8.type_label, " ", (_11 = (_10 = (_9 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _9 === void 0 ? void 0 : _9.transaction_detail) === null || _10 === void 0 ? void 0 : _10.investment) === null || _11 === void 0 ? void 0 : _11.friendly_id] })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Income Total" }), _jsxs("div", Object.assign({ className: ((_14 = (_13 = (_12 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _12 === void 0 ? void 0 : _12.transaction_detail) === null || _13 === void 0 ? void 0 : _13.journal_entry_item) === null || _14 === void 0 ? void 0 : _14.debit_amount) !== null
                                                        ? "red-important"
                                                        : "" }, { children: [((_17 = (_16 = (_15 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _15 === void 0 ? void 0 : _15.transaction_detail) === null || _16 === void 0 ? void 0 : _16.journal_entry_item) === null || _17 === void 0 ? void 0 : _17.credit_amount) === null
                                                            ? "("
                                                            : "", "$", formatNumber((_25 = unitsDividedByMultiplier((_21 = (_20 = (_19 = (_18 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _18 === void 0 ? void 0 : _18.transaction_detail) === null || _19 === void 0 ? void 0 : _19.journal_entry_item) === null || _20 === void 0 ? void 0 : _20.debit_amount) !== null && _21 !== void 0 ? _21 : (_24 = (_23 = (_22 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _22 === void 0 ? void 0 : _22.transaction_detail) === null || _23 === void 0 ? void 0 : _23.journal_entry_item) === null || _24 === void 0 ? void 0 : _24.credit_amount, 100)) !== null && _25 !== void 0 ? _25 : 0), ((_28 = (_27 = (_26 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _26 === void 0 ? void 0 : _26.transaction_detail) === null || _27 === void 0 ? void 0 : _27.journal_entry_item) === null || _28 === void 0 ? void 0 : _28.credit_amount) === null
                                                            ? ")"
                                                            : ""] }))] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Total Investment Interest Income" }), _jsxs("div", Object.assign({ className: ((_30 = (_29 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _29 === void 0 ? void 0 : _29.transaction_detail) === null || _30 === void 0 ? void 0 : _30.total_investment_income) <
                                                        0
                                                        ? "red-important"
                                                        : "" }, { children: [((_32 = (_31 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _31 === void 0 ? void 0 : _31.transaction_detail) === null || _32 === void 0 ? void 0 : _32.total_investment_income) <
                                                            0
                                                            ? "("
                                                            : "", "$", formatNumber(unitsDividedByMultiplier(Math.abs((_35 = (_34 = (_33 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _33 === void 0 ? void 0 : _33.transaction_detail) === null || _34 === void 0 ? void 0 : _34.total_investment_income) !== null && _35 !== void 0 ? _35 : 0), 100)), ((_37 = (_36 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _36 === void 0 ? void 0 : _36.transaction_detail) === null || _37 === void 0 ? void 0 : _37.total_investment_income) <
                                                            0
                                                            ? ")"
                                                            : ""] }))] }))] })), _jsx("div", { children: _jsxs(Table, Object.assign({ className: "full-width-form", showSearchBar: false, title: "", emptyText: "Empty", isEmpty: ((_39 = (_38 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _38 === void 0 ? void 0 : _38.transaction_detail) === null || _39 === void 0 ? void 0 : _39.ownership_certificates) ===
                                            undefined ||
                                            ((_41 = (_40 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _40 === void 0 ? void 0 : _40.transaction_detail) === null || _41 === void 0 ? void 0 : _41.ownership_certificates.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "contact-detail-header section-header" }, { children: [_jsx(RawCell, { children: "Ownership" }), _jsx(RawCell, { children: "Ownership in Period" }), _jsx(RawCell, { children: "Rate" }), _jsxs(RawCell, Object.assign({ className: "right" }, { children: [((_43 = (_42 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _42 === void 0 ? void 0 : _42.transaction_detail) === null || _43 === void 0 ? void 0 : _43.ownership_certificates[0].type) === "detailed"
                                                                ? "Estimated "
                                                                : "", "Income Amount"] }))] })), (_46 = (_45 = (_44 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _44 === void 0 ? void 0 : _44.transaction_detail) === null || _45 === void 0 ? void 0 : _45.ownership_certificates) === null || _46 === void 0 ? void 0 : _46.map((ownership_certificate, index) => {
                                                var _a, _b;
                                                return (_jsxs(BasicRow, { children: [_jsxs(TextCell, { children: [ownership_certificate.percent.toFixed(2), "%"] }, index + "-percent"), _jsx(TextCell, { children: ownership_certificate.date }, index + "-period"), _jsxs(TextCell, { children: [formatNumberToMoneyFormat(ownership_certificate.rate), "%"] }, index + "-rate"), _jsxs(TextCell, Object.assign({ className: "right" }, { children: ["$", formatNumber((_a = unitsDividedByMultiplier(ownership_certificate.total_amount, 100)) !== null && _a !== void 0 ? _a : 0), ownership_certificate.type === "detailed" && (_jsxs(_Fragment, { children: [" (+", "$", formatNumberToMoneyFormat((_b = ownership_certificate.original_amount) !== null && _b !== void 0 ? _b : 0, 5), ")"] }))] }), index + "-amount")] }, index));
                                            }), _jsxs(BasicRow, Object.assign({ rowClasses: ["table-row-total"] }, { children: [_jsx(TextCell, {}, "total-percent"), _jsx(TextCell, {}, "total-period"), _jsx(TextCell, {}, "total-rate"), _jsxs(TextCell, Object.assign({ className: "right" }, { children: ["$", formatNumber((_54 = unitsDividedByMultiplier((_50 = (_49 = (_48 = (_47 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _47 === void 0 ? void 0 : _47.transaction_detail) === null || _48 === void 0 ? void 0 : _48.journal_entry_item) === null || _49 === void 0 ? void 0 : _49.debit_amount) !== null && _50 !== void 0 ? _50 : (_53 = (_52 = (_51 = flash === null || flash === void 0 ? void 0 : flash.form) === null || _51 === void 0 ? void 0 : _51.transaction_detail) === null || _52 === void 0 ? void 0 : _52.journal_entry_item) === null || _53 === void 0 ? void 0 : _53.credit_amount, 100)) !== null && _54 !== void 0 ? _54 : 0)] }), "total-amount")] }), "total")] })) })] })), loading === true && _jsx(Loading, { style: { width: "50px" } })] })] })) }));
};
export default InterestIncomeTransactionDetail;
