import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Form, Column, SearchComponent } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { router, usePage } from "@inertiajs/react";
import { getParamFromUrlString } from "../../utils/utils";
const GlobalSearch = () => {
    const url = usePage().url;
    const location = useLocation();
    const [searchParam, setSearchParam] = useState(getParamFromUrlString(url, "search"));
    const [showSearch, setShowSearch] = useState(false);
    const showHideSearch = () => {
        if (showSearch === false) {
            setShowSearch(true);
        }
        else {
            setShowSearch(false);
        }
    };
    const globalSearch = () => {
        const search = searchParam;
        router.get("/search?search=" + (search !== null && search !== void 0 ? search : ""));
    };
    const handleSearch = () => {
        globalSearch();
    };
    useEffect(() => { }, [location]);
    // Set the focus on the input when its clicked !
    useEffect(() => {
        const searchInput = document.getElementById("global_search");
        if (searchInput && showSearch) {
            searchInput.focus();
        }
    }, [showSearch]);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "global-search" }, { children: [showSearch === true && (_jsx(_Fragment, { children: _jsx(Form, Object.assign({ className: `form-container ${showSearch ? "fade-in" : ""}` }, { children: _jsxs(Column, Object.assign({ columnStyle: "full" }, { children: [_jsx(SearchComponent, { id: "global_search", fieldName: "global-search", placeholder: "Global Search", allowRouteChanges: true, valueChanged: setSearchParam, defaultValue: searchParam !== null && searchParam !== void 0 ? searchParam : "", onEnterPress: handleSearch }), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", onClick: globalSearch }, { children: "Search" }))] }), "form-columns") })) })), showSearch ? (_jsx(FontAwesomeIcon, { className: `show-hide-global-search ${showSearch ? "active-search" : ""}`, icon: "xmark", onClick: showHideSearch })) : (_jsx(FontAwesomeIcon, { className: `show-hide-global-search ${showSearch ? "active-search" : ""}`, icon: "magnifying-glass", onClick: showHideSearch }))] })) }));
};
export default GlobalSearch;
